<template>
<div class="list">

<div class="listMain wrap">
<div class="listMainBox">
<div class="listMainLeft fl">
<div class="tit">{{slideHdTitle}}</div>
<div class="sort">
<div v-for="(item, index) in slideList" :key="index">
<a 
                v-if="item.url && item.url !== ''" 
                target="_blank" 
                :class="{ck : active  == item.entTChannelUuid}"
                :href="item.url">
                {{item.name}}
              </a>
<a 
                v-if="!item.url || item.url == ''" 
                :class="{ck : active  == item.entTChannelUuid}"
                :href="item.catalog === 1 ? `/listPage/${item.entTChannelUuid}` : item.catalog === 2 ? `/list/${item.entTChannelUuid}` : `/listPic/${item.entTChannelUuid}`">
                {{item.name}}
              </a>
</div>
</div>
<!-- <div class="listSlid">
<div class="listSlidHd">
              {{slideHdTitle}}
            </div>
<div class="listSlidList">
<div class="listSlidListInner" :class="{on : active  == item.entTChannelUuid}" v-for="(item, index) in slideList" :key="index">
<a 
                  v-if="item.url && item.url !== ''" 
                  target="_blank" 
                  :href="item.url">
                  {{item.name}}
                </a>
<a 
                  v-if="!item.url || item.url == ''" 
                  :href="item.catalog === 1 ? `/listPage/${item.entTChannelUuid}` : item.catalog === 2 ? `/list/${item.entTChannelUuid}` : `/listPic/${item.entTChannelUuid}`">
                  {{item.name}}
                </a>
<span></span>
</div>
</div>
</div>-->
</div>
<div class="listMainRight fr">
<div class="listWrap">
<div class="listHd clearfloat">
<div class="fl listHdGropL">
<span>{{slideHdName}}</span>
</div>
<div class="fr listHdGrop">
<span class="listHdIco"></span>您当前的位置：<a href="/">首页</a>><span v-if="viewSlideHdParent"><a :href="slideHdParentUrl">{{slideHdParentName}}</a>></span><span class="txt">{{slideHdName}}</span>
</div>
</div>
<div class="listContainer listPicBox">
<el-row :gutter="30">
<el-col :span="8" v-for="(item, index) in listData" :key="index">
<a target="_blank" class="listPicInner" :href="item.url && item.url !== '' ? item.url : `/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
<div class="listPicImg">
<img :src="item.thumb1" alt="">
</div>
<h3 class="listPicTitle single-line">{{item.title}}</h3>

</a>
</el-col>
</el-row>
<div class="listPage" v-if="listData.length!=0">
<el-pagination
                  background
                  layout="prev, pager, next"
                  :current-page="pageNum"
                  :page-size="pageSize"
                  :total="total"
                  @current-change="currentChange">
</el-pagination>
</div>
              
            </div>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
import { getChildChannel, getChannelInfo, articles } from '@/api/common'

export default {
  name: 'list',
  data(){
    return {
      slideHdTitle: '',
      viewSlideHdParent: true,
      slideHdParentName: '',
      slideHdParentUrl: '',
      slideHdName: '',
      hdTitle: '',
      hdName: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '新闻动态',
      },
      slideList:[],
      pageSize: 9,
      pageNum: 1,
      total: 0,
      listData: [],
      listId: ''
    }
  },
  mounted(){
    var _this = this
    _this.active = _this.$route.params.id
    _this.listId = _this.$route.params.id
    // _this.getChildChannel()
    _this.getChannelInfo()
    _this.articles()
  },
  methods: {
    // 查询二级栏目
    getChildChannel(id) {
      var _this = this

      let data = {
        applicationUuid: _this.webId,
        parentUuid: id
      }
      getChildChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideList = data
          if (_this.slideList.length) {
            _this.viewSlideHdParent = true
            let url = ''
            if (_this.slideList[0].catalog === 1) {
              url = `/listPage/${_this.slideList[0].entTChannelUuid}`
            } else if (_this.slideList[0].catalog === 2) {
              url = `/list/${_this.slideList[0].entTChannelUuid}`
            } else {
              url = `/listPic/${_this.slideList[0].entTChannelUuid}`
            }
            _this.slideHdParentUrl = url
          } else {
            _this.viewSlideHdParent = false
          }
        }
      })
    },
    getChannelInfo() {
      var _this = this
      let data = {
        channelUuid: _this.$route.params.id
      }
      getChannelInfo(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideHdName = data.name
          if (data.parentUuid == "0") {
            _this.slideHdTitle = data.name
            _this.viewSlideHdParent = false
            return
          }

          _this.getChildChannel(data.parentUuid)
          
          let params = {
            channelUuid: data.parentUuid
          }
          getChannelInfo(params).then(res => {
            const { code, data } = res
            if (code === 1 && data) {
              _this.slideHdParentName = data.name
              _this.slideHdTitle = data.name
            }
          })
        }
      })
    },
    // 获取列表
    articles() {
      var _this = this
      let data = {
        entTApplicationUuid: _this.webId,
        channelUuid: _this.$route.params.id,
        pageNum: _this.pageNum,
        pageSize: _this.pageSize
      }
      articles(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.listData = data.rows
          _this.total = data.totalRows
        }
      })
    },
    currentChange(e){
      // console.log('e :>> ', e);
      var _this = this
      _this.pageNum = e
      _this.articles()
    }
  }
}
</script>
<style lang="css" scoped>
.listPicBox {
  padding-top: 30px;
}
.listPicInner {
  display: block;
  text-align: center;
  margin-bottom: 25px;
}
.listPicImg {
  height: 161px;
  overflow: hidden;
  border-radius: 6px;
}
.listPicImg img {
  width: 100%;
  height: 100%;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.listPicInner:hover img {
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
.listPicTitle {
  color: #070404;
  font-size: 14px;
  line-height: 48px;
}
.listPicMore {
  display: block;
  width: 80px;
  height: 32px;
  line-height: 32px;
  overflow: hidden;
  border: solid 1px #7B7B7B;
  margin: 0 auto;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.listPicMore:hover {
  color: #fff;
  background-color: #0162b1;
}
</style>